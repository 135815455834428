import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import Layout from "../components/layout"
import Img from "gatsby-image"
import store  from "../store"
import {
  Button,
  NeedHelp,
  Avantages,
  Spacer,
  Title,
  Input,
} from "../components/banners"
import styled, { keyframes } from "styled-components"

const Cart = styled.div`
  display: flex;
  justify-content: center;
`


const Form = () => {
  const member = useSelector(state => state.member)
  const thankYouCart = useSelector(state => state.thankYouCart)
  const dispatch = useDispatch()
  
  return (
    <div style={{ border: "1px solid black", padding: "15px 30px" }}>
      <Title>Merci {member && member.firstname} !</Title>
      Nous préparons votre commande.
<Cart>
  {thankYouCart && thankYouCart.items.map(({thumb}) => (
    <Img fixed={thumb} style={{margin: '20px'}} />
  ))}
</Cart>
    </div>
  )
}

export default class extends React.Component {
  componentDidMount() {
    store.dispatch({type: "THANK_YOU"})
  }
  render() {
  return (
    <Layout>
      <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr" }}>
        <div></div>
        <Form />
        <div></div>
      </div>
      <Spacer v="6rem" />
      <Avantages />
    </Layout>
  )
  }
}
